<template>
	<div class="terms--wrapper">
		<Header></Header>
		<div class="terms">
			<aside class="terms--aside">
				<div class="terms--navigations">
					<div>
						<h1>On this page</h1>
					</div>
					<div class="terms--navigation">
						<ul class="terms--lists">
							<li class="terms--list"><a href="#use">Terms of Use</a></li>
							<li class="terms--list"><a href="#service">Terms of Service</a></li>
						</ul>
					</div>
				</div>
			</aside>
			<main class="terms--main">
				<div class="">
					<div>
						<h1>Terms & Conditions</h1>
						<h4>Last updated on: 30th October 2020</h4>
					</div>
					<div class="terms--title" id="#use">
						<h2>Terms of Use</h2>
						<p>By using this website (www.algoforms.com),any of our websites and/or services, you agree to these Terms of Use. The website Privacy Policy, Acceptable Use Policy and Merchant Terms of Service (where applicable) are incorporated by reference into these Terms of Use.</p>
					</div>
					<div class="terms--items">
						<div class="terms--item">
							<h2>Privacy Policy</h2>
							<p>Algoforms is committed to managing your Personal Information in line with global industry best practices. You can read our Privacy Policy to understand how we use your information and the steps we take to protect your information.</p>
						</div>
						<div class="terms--item">
							<h2>Age Restriction</h2>
							<p>Our website and services are only directed to users of legal age. We do not knowingly provide any services to under-aged users.</p>
						</div>
						<div class="terms--item">
							<h2>Acceptable Use Policy</h2>
							<p>You are independently responsible for complying with all applicable laws related to your use of our website and services. However, by accessing or using Algoforms, you agree to comply with the terms and conditions of our Acceptable Use Policy which you can read on our Acceptable Use Policy page.</p>
						</div>
						<div class="terms--item">
							<h2>Exclusions</h2>
							<p>Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for certain damages. Accordingly, some of the above disclaimers and limitations of liability may not apply to you. To the extent that any Algoforms Party may not, as a matter of applicable law, disclaim any implied warranty or limit its liabilities, the scope and duration of such warranty and the extent of the Algoforms’s Party's liability shall be the minimum permitted under such applicable law.</p>
						</div>
						<div class="terms--item">
							<h2>Updates, Modifications & Amendments</h2>
							<p>We may need to update, modify or amend our Terms of Use as our technology evolves. We reserve the right to make changes to this Terms of Use at any time by giving notice to users on this page.</p>
							<p>We advise that you check this page often, referring to the date of the last modification on the page If a user objects to any of the changes to the Terms of Use, the User must cease using our website and/or services immediately.</p>
						</div>
					</div><hr style="border-bottom: 1px solid rgba(209,213,219, 1);">
					<div class="terms--title" id="#service">
						<h2>Terms of Service</h2>
						<p>By signing up for an account on this website (www.algoforms.com), any of our websites and/or services, you are deemed a merchant and agree to these Merchant Terms of Service (the “Agreement”).</p>
					</div>
					<div class="terms--items">
						<div class="terms--item">
							<h2>Registration</h2>
							<p>To use Algoforms, you have to create a Algoforms account by registering. To register, you will provide us with certain information such as your email, first name, last name, business name and phone number and we may seek to verify your information, (by ourselves or through third parties), after which we will approve your account unless deemed risky. You give us permission to do all these.</p>
						</div>
						<div class="terms--item">
							<h2>Data Compliance</h2>
							<p>You agree to comply with all data privacy and security requirements. We acknowledge that you own all your users' data.</p>
							<p>We are responsible for the security and protection of data we collect and store. Accordingly, we implement access control measures, security protocols and standards including the use of encryption and firewall technologies to ensure that data is kept safe and secure on our servers, in compliance with the relevant Requirement.</p>
							<p>We also implement periodical security updates to ensure that our security infrastructures are in compliance with reasonable industry standards.</p>
						</div>
					</div><hr style="border-bottom: 1px solid rgba(209,213,219, 1);">
				</div>
			</main>
		</div>
		<Main></Main>
		<Footer></Footer> 
	</div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/partials/Header.vue";
import Footer from "@/components/partials/Footer.vue";
import Main from "@/components/partials/Main.vue";
export default {
  name: "Terms",
  components: {
    Header,
    Footer,
    Main,
  },
};
</script>

<style scoped>
/* Terms */
.terms {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  margin-top: 1rem;
}
.terms--aside {
  display: none;
  grid-column: 1 / 2;
}
.terms--navigations {
  text-align: right;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.terms--lists {
  margin-top: 1rem;
}
.terms--list {
  list-style-type: disc;
  padding-top: 0.75rem;
}
.terms--main {
  grid-column: 1 / 6;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.terms--title {
  text-align: center;
  margin-bottom: 0.75rem;
  margin-top: 3rem;
}
.terms--items {
  text-align: left;
}
.terms--item {
  margin-block: 2rem;
}
@media only screen and (min-width: 981px) {
  .terms {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    margin-top: 1rem;
  }
  .terms--aside {
    display: grid;
    grid-column: 1 / 2;
  }
  .terms--main {
    grid-column: 2 / 6;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}
</style>